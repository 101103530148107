const Project = ({img, html, css, figma, js, react, bootstrap, node, express, mongodb, link, next}) => {
    
    const navigateToSite = () => {
        window.open(link, '_blank');
    }

    return ( 
        <div className="project" onClick={navigateToSite}>
            <img src={img} alt="Project" className="screenshot-img"/>
            <div className="tools">
                {html && <img src={html} alt="tool-used" />}
                {css && <img src={css} alt="tool-used" />}
                {bootstrap && <img src={bootstrap} alt="tool-used" />}
                {figma && <img src={figma} alt="tool-used" />}
                {mongodb && <img src={mongodb} alt="tool-used" />}
                {express && <img src={express} alt="tool-used" />}
                {react && <img src={react} alt="tool-used" />}
                {node && <img src={node} alt="tool-used" />}
                {next && <img src={next} alt="tool-used" />}
            </div>
        </div>
     );
}
 
export default Project;