import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setToNull } from '../store/slices/currentPage.js';

const Education = ({img, h3, p1, p2, p3, hasBtn, navigateTo, internship, googleDrive}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const navigateToDetails = () => {
        navigate(navigateTo)
        dispatch(setToNull())
    }

    const navigateToCredential = () => {
        window.open("https://credsverse.com/credentials/2ff9714f-327f-43ac-86e0-f95097c333bf", '_blank');
    }

    const navigateToCredential2 = () => {
        window.open("https://drive.google.com/file/d/1hSFg7TiWLEky4goMpgxAzWXYKUsmtDi8/view?usp=sharing", '_blank');
    }

    return ( 
        <div className="edu">
            <img src={img} alt="=Certificate-img"/>
            <h3>{h3}</h3>
            <div className="info">
                <p>{p1}</p>
                <p>{p2}</p>
                {p3 && <p>{p3}</p>}
            </div>
            {hasBtn && (
                internship ? (
                    <button className="tertiary-btn" onClick={googleDrive ? navigateToCredential2 : navigateToCredential} style={{width: "120px"}}><a>See Credentials</a></button>
                ) : (
                    <button className="tertiary-btn" onClick={navigateToDetails}><a>View Details</a></button>
                )
            )}
        </div>
     );
}
 
export default Education;