import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import logo from "../images/fruity-logo.png";

import prototype from '../images/prototype10.png'
import triangle from '../images/triangle.png'

import figma from '../images/figma.png'
import nextjs from "../images/nextjs.png";
import mongodb from '../images/mongodb.png'
import planning from '../images/plan.png'
import design from '../images/design.png'
import coding from '../images/coding.png'

import wireframe10 from '../images/wireframe10.png'
import wireframe11 from '../images/wireframe11.png'
import wireframe12 from '../images/wireframe12.png'


import prototype7 from '../images/prototype7.png'
import prototype8 from '../images/prototype8.png'
import prototype9 from '../images/prototype9.png'


const FruityStore = () => {
    const { pathname } = useLocation();

    const navigateToPrototype = () => {
        window.open("https://www.figma.com/design/8liUjcSoeib9rL8P2hzvnr/Ecommerce-Website?node-id=0-1&t=uyUqWp5hEk6W0FOS-1", '_blank');
    }

    const navigateToSite = () => {
        window.open("https://online-store-one-phi.vercel.app/", '_blank');
    }

    const navigateToSourcecode = () => {
        window.open("https://github.com/Alix144/online-store.git", '_blank');
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='class101'>
            <div className="scroll-watcher"></div>
            <div className="app-logo">
                <figure>
                    <img src={logo} alt="Fruity-store-logo" />
                    <div className="light glow3"></div>
                </figure>
                <h1>Fruity Store</h1>
            </div>
            <img src={prototype} alt="application-prototype" className='prototype2'/>

            <section className="app-introduction">
                <div className="overview">
                    <h2>Overview</h2>
                    <div>
                        <h3>Project Type:</h3>
                        <p>Personal</p>
                    </div>
                    <div>
                        <h3>Timeline:</h3>
                        <p>3 Weeks</p>
                    </div>
                    <div>
                        <h3>Tools Used:</h3>
                        <div className="tools-icons">
                            <img src={figma} alt="Figma" />
                            <img src={nextjs} alt="NextJs" />
                            <img src={mongodb} alt="MongoDB" />
                        </div>
                    </div>
                    <div className="btns">
                        <button className="btn primary-btn" onClick={navigateToSite}><a>Visit Website</a></button>
                        <button className="btn secondary-btn" onClick={navigateToSourcecode}><a>Source Code</a></button>
                    </div>
                </div>
                <div className="details">
                    <div className="introduction">
                        <h2>Introduction</h2>
                        <p>I developed a full-stack e-commerce application using Next.js, featuring complete CRUD operations for product management, user authentication, and a responsive design. The app enables users to browse, search, and purchase products, with admins able to manage listings efficiently. This project highlights my skills in creating dynamic, user-focused web applications that handle data effectively.</p>
                    </div>

                    <div className="problem">
                        <h2>Problem</h2>
                        <p>Many small stores primarily rely on phone calls to handle order deliveries, which can be inefficient and limit customer convenience. This approach often lacks streamlined order management and can lead to miscommunications or delays. To address these challenges, this project introduces a full-stack e-commerce app that allows users to browse, filter, and place orders online, providing a smooth, user-friendly alternative to phone-based orders. This app also empowers store owners with easy inventory management and real-time updates, enhancing both the customer experience and operational efficiency.</p>
                    </div>

                    <div className="solution">
                        <h2>Solution</h2>
                        <p>This e-commerce app offers small stores a complete online platform to manage and fulfill orders seamlessly. By allowing customers to browse, filter, and place orders directly through the app, it eliminates the reliance on phone-based orders, reducing errors and improving the shopping experience. The app includes an intuitive interface for product management, real-time inventory updates, and a streamlined checkout process. For store owners, this solution provides powerful tools to oversee orders efficiently, creating a smoother, more accessible, and reliable shopping experience for both businesses and customers.</p>
                    </div>

                    <div className="features">
                        <h2>Key Features</h2>
                        <ul>
                            <p style={{fontWeight: "bold"}}>Customer:</p>
                            <li>Login using Google authentication.</li>
                            <li>View store products.</li>
                            <li>Add products to favorites.</li>
                            <li>Add products to cart.</li>
                            <li>Manage profile.</li>
                        </ul>
                        <br />
                        <ul>
                            <p style={{fontWeight: "bold"}}>Admin:</p>
                            <li>Login using Google authentication.</li>
                            <li>Add / Edit / Delete products.</li>
                            <li>View coming orders.</li>
                            <li>View users.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="process">
                <h2>The Process</h2>
                <div className="phases">
                    <div className="phase">
                        <img src={planning} alt="planning-phase" />
                        <caption>Planning & Analysis</caption>
                    </div>
                    <hr />
                    <div className="phase">
                        <img src={design} alt="design-phase" />
                        <caption>Design</caption>
                    </div>
                    <hr />
                    <div className="phase">
                        <img src={coding} alt="coding-phase" />
                        <caption>Coding</caption>
                    </div>
                </div>
                <div className="line line-1"></div>
                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>

            <section className="planning">
                <img src={triangle} alt="Triangle-stars" />
                <h2><span className='numbers'>1.</span>Planning & Analysis</h2>
                <p>In this initial phase, I identified key requirements by analyzing the challenges faced by small stores relying on phone-based orders. I defined essential features, including a user-friendly product catalog, secure checkout, and streamlined admin functionalities for inventory and order management.</p>
            </section>

            <section className="design">
                <h2><span className='numbers'>2.</span>Design</h2>
                <p>I designed the app with a focus on usability, creating wireframes and prototypes that mapped the user journey from browsing to checkout. The design emphasizes a responsive, visually appealing interface to enhance the user experience on all screens.</p>
                <div>
                    <h3>Wireframes</h3>
                    <p>Basic wireframes drawings were created to visualize and reduce products down to their basic structures and highlight each element’s intended function</p>
                    <div className="wireframes">
                        <img src={wireframe12} alt="Wireframe"/>
                        <img src={wireframe10} alt="Wireframe"/>
                        <img src={wireframe11} alt="Wireframe"/>
                    </div>
                </div>
                <div>
                    <h3>High-Fidelity Prototype</h3>
                    <p>The high-fidelity prototype represents a significant advancement from the wireframes, providing a detailed and visually polished rendition of the user interface using Figma.</p>
                    <div className="prototypes">
                        <img src={prototype8} alt="Prototype" />
                        <img src={prototype7} alt="Prototype" />
                        <img src={prototype9} alt="Prototype" />
                    </div>
                    <div className='btns'>
                        <button className="btn primary-btn" onClick={navigateToPrototype}><div className="glow"></div><img src={figma} alt="Figma" /><a>View in Figma</a></button>
                    </div>
                </div>
            </section>

            <section className="coding">
                <div className="line line-1"></div>
                <h2><span className='numbers'>3.</span>Coding</h2>
                <p>In the development phase, I built the front-end using React components and Next.js for server-side rendering, while integrating a backend to handle CRUD operations. I implemented authentication, product filtering, and data management to ensure the app functioned smoothly across all features, meeting both customer and admin needs.</p>
            </section>

            <div className='visit-app'>
                <div className="app-logo">
                    <figure>
                        <img src={logo} alt="Fruity store logo" />
                        <div className="light glow3"></div>
                    </figure>
                    <h1>Fruity Store</h1>
                </div>
                <button className="btn primary-btn" onClick={navigateToSite}><a>Visit</a></button>
                <div className="nova4"></div>
            </div>

        </main>
     );
}
 
export default FruityStore;