import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setToNull,
  setToHome,
  setToAbout,
  setToContact,
  setToFreelance,
} from "../store/slices/currentPage.js";

//*******Images ********/
import heroImg from "../images/hero-img.png";
import ali from "../images/ali.png";
import linkedin from "../images/linkedin.png";
import github from "../images/github.png";
import instagram from "../images/instagram.png";
import dribbble from "../images/dribbble.png";
import bear from "../images/bear.png";
import bear2 from "../images/bear2.png";
import triangle from "../images/triangle.png";
//tools//
import html from "../images/html.png";
import css from "../images/css.png";
import tailwind from "../images/tailwind.png";
import ux from "../images/ux.png";
import figma from "../images/figma.png";
import js from "../images/js.png";
import nextjs from "../images/nextjs.png";
import react from "../images/react.png";
import github2 from "../images/github2.png";
import bootstrap from "../images/bootstrap.png";
import node from "../images/node.png";
import express from "../images/express.png";
import mongodb from "../images/mongodb.png";
//languages//
import arabic from "../images/arabic.png";
import uk from "../images/uk.png";
import turkish from "../images/turkish.png";
import spanish from "../images/spanish.png";
//certificates screenshots//
import uskudar from "../images/uskudar.png";
import meta from "../images/meta.png";
import google from "../images/google.png";
import internship from "../images/internship.png";
import internship2 from "../images/internship2.jpg";
//projects screenshots//
import myGym from "../images/myGym.png";
import site from "../images/site.png";
import leb from "../images/leb.png";
import site2 from "../images/site2.png";
import barber from "../images/barber.png";
import outlet from "../images/outlet.png";
import coffee from "../images/coffee.png";
import proje from "../images/proje.png";
import figmaDesign from "../images/figmaDesign.png";
import figmaDesign2 from "../images/macycleDesign.png";
import figmaDesign3 from "../images/ecommerce-design.png";
//case study//
import class101Logo from "../images/class101-logo.png";
import ghazalLebnanLogo from "../images/ghazalLebnanLogo.png";
import prototype from "../images/prototype.png";
import prototype5 from "../images/prototype5.png";
import fruityPrototype from "../images/fruity-prototype.png";
import fruityLogo from "../images/fruity-logo.png";

//*******Components********/
import Skill from "./Skill";
import Education from "./Education";
import Project from "./Project";

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToProject = (path) => {
    navigate(`/freelance/project/${path}`);
    dispatch(setToNull());
  };

  const navigateToCaseStudy = () => {
    navigate("/case-study/class101");
    dispatch(setToNull());
  };

  const navigateToCaseStudy2 = () => {
    navigate("/case-study/fruity-store");
    dispatch(setToNull());
  };

  const navigateToContact = () => {
    navigate("/contact");
    dispatch(setToContact());
  };

  const navigateToGithub = () => {
    window.open("https://github.com/Alix144", "_blank");
  };
  const navigateToLinkedin = () => {
    window.open("https://linkedin.com/in/ali-youssef-profile", "_blank");
  };
  const navigateToInstagram = () => {
    window.open("https://instagram.com/aliyoussef404", "_blank");
  };
  const navigateToDribbbles = () => {
    window.open("https://dribbble.com/AliX144", "_blank");
  };
  const navigateToFreelance = () => {
    navigate("/freelance");
    dispatch(setToFreelance());
  };

  const handleDownload = () => {
    window.open(`${process.env.PUBLIC_URL}/Ali-Youssef-CV.pdf`, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main>
      <section className="hero">
        <div className="line line-1"></div>
        <div className="line line-2"></div>

        <div className="intro">
          <h1>
            Hi, I'm Ali. <br />
            Crafting beautiful websites just for you!
          </h1>

          <div className="social-media display">
            <img
              src={linkedin}
              alt="Linkedin-logo"
              onClick={navigateToLinkedin}
            />
            <img src={github} alt="Github-logo" onClick={navigateToGithub} />
            <img
              src={instagram}
              alt="Instagram logo"
              onClick={navigateToInstagram}
            />
            <img
              src={dribbble}
              alt="Dribbble-logo"
              onClick={navigateToDribbbles}
            />
          </div>

          <div className="btns display">
            <button
              className="btn primary-btn"
              style={{ marginRight: "20px" }}
              onClick={navigateToFreelance}
            >
              <a>Get a Website</a>
            </button>
            <button className="btn secondary-btn" onClick={navigateToContact}>
              <a href="">Contact Me</a>
            </button>
          </div>

          <img src={bear} alt="Ursa Major" className="bear" />
        </div>

        <figure>
          <div className="hero-img">
            <img src={heroImg} alt="Hero-img-frame" className="lines" />
            <img src={ali} alt="Hero-img-Ali" className="ali" />
            <div className="dot top-right"></div>
            <div className="dot top-left"></div>
            <div className="dot bottom-left"></div>
            <div className="dot bottom-right"></div>

            <div className="nova">
              <div className="color-1"></div>
              <div className="color-2"></div>
              <div className="color-3"></div>
              <div className="color-4"></div>
            </div>

            <div className="black-layer"></div>
          </div>
        </figure>

        <div className="hidden-div">
          <div className="social-media">
            <img src={linkedin} alt="linkedin" onClick={navigateToLinkedin} />
            <img src={github} alt="github" onClick={navigateToGithub} />
            <img
              src={instagram}
              alt="Instagram logo"
              onClick={navigateToInstagram}
            />
            <img src={dribbble} alt="dribbble" onClick={navigateToDribbbles} />
          </div>

          <div className="btns">
            <button
              className="btn primary-btn"
              style={{ marginRight: "20px" }}
              onClick={navigateToFreelance}
            >
              <a>Get a Website</a>
            </button>
            <button className="btn secondary-btn" onClick={navigateToContact}>
              <a href="">Contact Me</a>
            </button>
          </div>
        </div>
      </section>

      <section className="about">
        <h2>About Me</h2>
        <p>
          Welcome to my space! <br />
          I'm a software engineering graduate based in Kuwait, I began my career
          studying software engineering and have since gained a lot of knowledge
          and experience through numerous university projects and various
          specialized courses.
        </p>

        <p>
          I specialize in creating seamless user experiences (UX) and
          captivating user interfaces (UI) that translate into engaging and
          functional websites. With a strong foundation in software engineering,
          I design and develop websites that not only look great but also
          prioritize usability and user satisfaction.
        </p>

        <div className="nova2"></div>

        <div class="orbit">
          <div class="rotating-item">
            <div class="sun"></div>
            <div class="ball"></div>
          </div>
        </div>
      </section>

      <section className="skills">
        <h2>My Skills</h2>
        <div className="skills-container">
          <Skill
            skill={"HTML"}
            skillImg={html}
            rate={5}
            color={"#FF7816"}
            keyframe={"glow1"}
          />
          <Skill
            skill={"CSS"}
            skillImg={css}
            rate={4}
            color={"#35A8DB"}
            keyframe={"glow2"}
          />
          <Skill
            skill={"Tailwind"}
            skillImg={tailwind}
            rate={4}
            color={"#35A8DB"}
            keyframe={"glow2"}
          />
          <Skill
            skill={"UX/UI Design"}
            skillImg={ux}
            rate={4}
            color={"#1f6fda"}
            keyframe={"glow3"}
          />
          <Skill
            skill={"Figma"}
            skillImg={figma}
            rate={4}
            color={"#FF7362"}
            keyframe={"glow4"}
          />
          <Skill
            skill={"Javascript"}
            skillImg={js}
            rate={4}
            color={"#FFC107"}
            keyframe={"glow5"}
          />
          <Skill
            skill={"Next JS"}
            skillImg={nextjs}
            rate={4}
            color={"#fff"}
            keyframe={"glow10"}
          />
          <Skill
            skill={"React JS"}
            skillImg={react}
            rate={4}
            color={"#35A8DB"}
            keyframe={"glow6"}
          />
          <Skill
            skill={"Version Control"}
            skillImg={github2}
            rate={4}
            color={"#fff"}
            keyframe={"glow7"}
          />
          <Skill
            skill={"Bootstrap"}
            skillImg={bootstrap}
            rate={3}
            color={"#8612FB"}
            keyframe={"glow8"}
          />
          <Skill
            skill={"Node JS"}
            skillImg={node}
            rate={3}
            color={"#014A05"}
            keyframe={"glow9"}
          />
          <Skill
            skill={"Express JS"}
            skillImg={express}
            rate={3}
            color={"#fff"}
            keyframe={"glow10"}
          />
          <Skill
            skill={"MongoDB"}
            skillImg={mongodb}
            rate={3}
            color={"#00ED64"}
            keyframe={"glow11"}
          />
        </div>
        <div className="planet">
          <div className="planet-moon"></div>
        </div>
      </section>

      <section className="languages">
        <h2>Languages</h2>
        <div className="skills-container">
          <Skill
            skill={"Arabic"}
            skillImg={arabic}
            rate={5}
            color={"#00ED64"}
            keyframe={"glow12"}
          />
          <Skill
            skill={"English"}
            skillImg={uk}
            rate={5}
            color={"#FF4B55"}
            keyframe={"glow13"}
          />
          <Skill
            skill={"Turkish"}
            skillImg={turkish}
            rate={4}
            color={"#fff"}
            keyframe={"glow14"}
          />
          <Skill
            skill={"Spanish"}
            skillImg={spanish}
            rate={1}
            color={"#FFE600"}
            keyframe={"glow15"}
          />
        </div>
        <div className="line line-3"></div>
      </section>

      <section className="education">
        <h2>Education & Certifications</h2>
        <div className="education-container">
          <Education
            img={uskudar}
            h3={"Bachelor of Engineering in Software Engineering"}
            p1={"Uskudar University, Istanbul, Turkiye"}
            p2={"Graduation Date: 06/2024"}
            p3={"GPA: 3.7 (High honor)"}
            hasBtn={false}
          />
          <Education
            img={meta}
            h3={"Frontend Meta Certifications"}
            p1={"Company: Meta"}
            p2={"Date: 06/2023"}
            hasBtn={true}
            navigateTo={"/meta-certificates"}
          />
          <Education
            img={google}
            h3={"Google UX Design Certification"}
            p1={"Company: Google"}
            p2={"Date: 03/2024"}
            hasBtn={true}
            navigateTo={"/google-certificates"}
          />
        </div>
        <img src={bear2} alt="Ursa Minor" className="bear2" />
      </section>

      <section className="education">
        <h2>Experience</h2>
        <div className="education-container">
          <Education
            img={internship}
            h3={"Internship"}
            p1={"Role: Software Engineering intern"}
            p2={"Company: EARTECH IT, Istanbul, Türkiye"}
            p3={"Duration: 20 days"}
            hasBtn={true}
            internship={true}
            googleDrive={false}
          />
          <Education
            img={internship2}
            h3={"Internship"}
            p1={"Role: Software Projects Intern"}
            p2={"Company: Emstell Technology, Kuwait"}
            p3={"Duration: 2 months"}
            hasBtn={true}
            internship={true}
            googleDrive={true}
          />
        </div>
      </section>

      <section className="work">
        <h2>Work</h2>
        <div className="sub-section">
          <h3>Personal Work</h3>
          <div className="long-line"></div>
        </div>
        <div className="work-container" style={{ marginBottom: "30px" }}>
          <div className="case-studies">
            <h4>Case Studies</h4>
            <div className="case-studies-container">
              <div className="case-study blue" onClick={navigateToCaseStudy}>
                <img src={class101Logo} alt="" className="a" />
                <img src={prototype} alt="app-prototype" className="b" />
              </div>
            </div>
            <div className="case-studies-container">
              <div className="case-study orange" onClick={navigateToCaseStudy2}>
                <img
                  src={fruityLogo}
                  alt="Fruity project logo"
                  className="fruity-logo"
                />
                <img
                  src={fruityPrototype}
                  style={{
                    width: "200px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "-50px",
                  }}
                  alt="app-prototype"
                  className="b"
                />
              </div>
            </div>
          </div>

          <div className="other-projects">
            <h4>Other Projects</h4>
            <div className="other-projects-container">
              <Project
                img={figmaDesign3}
                figma={figma}
                mongodb={mongodb}
                next={nextjs}
                link={"https://online-store-one-phi.vercel.app/"}
              />
              <Project
                img={myGym}
                mongodb={mongodb}
                express={express}
                react={react}
                node={node}
                link={"https://mern-my-gym.onrender.com/"}
              />
              <Project
                img={leb}
                html={html}
                css={css}
                bootstrap={bootstrap}
                link={"https://alix144.github.io/Lebanon/"}
              />
              <Project
                img={proje}
                html={html}
                css={css}
                bootstrap={bootstrap}
                link={"https://alix144.github.io/proje/"}
              />
              <Project
                img={barber}
                html={html}
                css={css}
                figma={figma}
                link={"https://alix144.github.io/barber-site/"}
              />
              <Project
                img={outlet}
                html={html}
                css={css}
                figma={figma}
                link={"https://alix144.github.io/outlet/"}
              />
              <Project
                img={coffee}
                html={html}
                css={css}
                figma={figma}
                link={"https://alix144.github.io/coffee-shop/"}
              />
              <Project
                img={site}
                html={html}
                css={css}
                link={"https://alix144.github.io/Responsive-Site/"}
              />
              <Project
                img={figmaDesign}
                figma={figma}
                link={
                  "https://www.figma.com/design/VocjcUmcAdzhjNr1IQyKd3/OSOSS?node-id=0-1&t=262LAoJKQ9PuCfBZ-1"
                }
              />
              <Project
                img={figmaDesign2}
                figma={figma}
                link={
                  "https://www.figma.com/design/M7hIDUvqdkgNTGbSeCpEwj/maCycle-design?node-id=0-1&t=hb24OSA9a8t836vI-1"
                }
              />
            </div>
          </div>
        </div>
        {/* ********** */}
        <div className="sub-section">
          <h3>Freelance Work</h3>
          <div className="long-line"></div>
        </div>

        <div className="work-container">
          <div className="case-studies case-studies-freelance">
            <div className="case-studies-container case-studies-container-freelance">
              <div
                className="case-study yellow"
                onClick={() => navigateToProject("ghazallebnan")}
              >
                <img
                  src={ghazalLebnanLogo}
                  alt="Logo"
                  className="a"
                  style={{ width: "120px", height: "80px" }}
                />
                <img src={prototype5} alt="app-prototype" className="b" />
              </div>
            </div>
            <button
              className="btn primary-btn"
              style={{ width: "150px", marginTop: "20px" }}
              onClick={navigateToFreelance}
            >
              <a>Get a Website</a>
            </button>
          </div>
        </div>

        <div className="nova">
          <div className="color-1"></div>
          <div className="color-2"></div>
          <div className="color-3"></div>
          <div className="color-4"></div>
        </div>
      </section>

      <section className="c2a">
        <img src={triangle} alt="stars triangle" className="stars-triangle" />
        <h2>Let’s Start The Journey!</h2>
        <p>Feel free to contact me for any inquiries or collaborations!</p>
        <div className="btns">
          <button
            className="btn primary-btn"
            style={{ marginRight: "20px" }}
            onClick={navigateToFreelance}
          >
            <a>Get a Website</a>
          </button>
          <button className="btn secondary-btn" onClick={navigateToContact}>
            <a href="">Contact Me</a>
          </button>
        </div>
        <div className="nova6">
          <div style={{ backgroundColor: "#FF00D4" }}></div>
          <div style={{ backgroundColor: "purple" }}></div>
          <div style={{ backgroundColor: "blue" }}></div>
          <div style={{ backgroundColor: "#8351D4" }}></div>
        </div>
      </section>
    </main>
  );
};

export default Main;
